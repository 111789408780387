



































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
  components: { BlurredTenant },
  data() {
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      self: this,
      chargingStation: null,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "startTime",
          "sort-desc": true,
          "class": "charging-sessions-table",
        },
        content: {
          name: "Charging Sessions",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor", "retailer"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "email",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "email",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              retailerId: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Retailer",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("retailers", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              chargerVendorId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Charger Vendor",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("chargerVendors", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              "chargingStationSnapshot.serialNumber": {
                attrs: {
                  label: "Serial Number",
                },
                rules: {
                  match: {},
                },
              },
              "chargingStationSnapshot.uniqueId": {
                attrs: {
                  label: "Charge Point ID",
                },
                rules: {
                  match: {},
                },
              },
              connectorId: {
                attrs: {
                  label: "Connector ID",
                },
                rules: {
                  equal_to: {},
                },
              },
              "details._id": {
                type: "integer",
                attrs: {
                  label: "Transaction ID",
                },
                rules: {
                  equal_to: {},
                },
              },
              "details.tagID": {
                attrs: {
                  label: "ID Tag",
                },
                rules: {
                  match: {},
                },
              },
              startTime: {
                type: "XTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Start Time (From)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Start Time (To)",
                    },
                  },
                },
              },
              stopTime: {
                type: "XTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "End Time (From)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "End Time (To)",
                    },
                  },
                },
              },
            },
          },
          displayFields: {
            "details._id": {
              text: "Transaction ID",
              options: {
                subProp: "details._id",
                sortBy: "details._id",
              },
            },
            startTime: {
              text: "Start Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            stopTime: {
              text: "End Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            uniqueId: {
              text: "Charging Station",
              options: {
                subProp: "chargingStationSnapshot.uniqueId",
                sortBy: "chargingStationSnapshot.uniqueId",
              },
            },
            user: {
              text: "User",
              options: {
                subProp: "userSnapshot.email",
                label: true,
                sortBy: "userId",
              },
            },
            rfid: {
              text: "RFID",
              options: {
                subProp: "tagSnapshot.value"
              },
            },
            vin: {
              text: "Vin",
              options: {
                subProp: "carSnapshot.vin"
              },
            },
            pointOrigin: {
              text: "Before Discount",
              options: {
                preTransform(value) {
                  const val = (value / 1).toFixed(0);
                  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                },
              },
            },
            point: {
              text: "After Discount",
              options: {
                preTransform(value) {
                  const val = (value / 1).toFixed(0);
                  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                },
              },
            },
            retailer: {
              text: "Retailer",
              options: {
                subProp: "retailerSnapshot.name",
                label: true,
                sortBy: "retailerId",
              },
            },
            chargerVendor: {
              text: "Charger Vendor",
              options: {
                subProp: "chargerVendorSnapshot.name",
                label: true,
                sortBy: "chargerVendorId",
              },
            },
            serialNumber: {
              text: "Serial Number",
              options: {
                subProp: "chargingStationSnapshot.serialNumber",
                sortBy: "chargingStationSnapshot.serialNumber",
              },
            },
            connectorId: {
              text: "Connector ID",
              sortable: true,
            },
            duration: {
              text: "Duration (minutes)",
              sortable: true,
              options: {
                subProp: "details.stop.totalDurationSecs",
                preTransform(value) {
                  return (value || 0) * 1000;
                },
                filter: "duration",
                sortBy: "details.stop.totalDurationSecs",
              },
            },
            meterStart: {
              text: "Meter Start (Wh)"
            },
            meterStop: {
              text: "Meter Stop (Wh)",
              options: {
                subProp: "details.stop.meterStop",
                preTransform(value) {
                  return (value || 0);
                },
                sortBy: "details.stop.meterStop",
                filter: "number3",
              },
            },
            energyDelivered: {
              text: "Energy Delivered (kWh)",
              sortable: true,
              options: {
                subProp: "details.stop.totalConsumptionWh",
                preTransform(value) {
                  return (value || 0) / 1000;
                },
                filter: "number3",
                sortBy: "details.stop.totalConsumptionWh",
              },
            },
            "soc": {
              text: "SoC",
            },
            "voltage": {
              text: "Voltage",
            },
            "powerActiveImport": {
              text: "Power.Active.Import (W)",
            },
            "currentImport": {
              text: "Current.Import (A)",
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
          },
          multiSelectActionButtons: {
            export: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor", "retailer"]) && {
              content: {
                text: "Export selected",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  coreApiClient.download("chargingSessions", `@/export`, {
                    payload: JSON.stringify({
                      filters: [
                        {
                          key: "_id",
                          operator: "in",
                          value: dataTable.model.value.map((item) => item._id),
                        },
                      ],
                      limit: -1,
                      extraParams: {
                        currentTimeZoneOffset: new Date().getTimezoneOffset() * -1 * 60 * 1000,
                      },
                    }),
                  });
                },
              },
            },
          },
          topActionButtons: {
            export: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor", "retailer"]) && {
              content: {
                text: "Export all",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  coreApiClient.download("chargingSessions", `@/export`, dataTable.getFindAllOptions());
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            const activeTenant = appService.getActiveTenant();
            if (activeTenant) {
              options.filters.push({
                key: "tenantId",
                operator: "equal_to",
                value: activeTenant._id,
              });
            }

            const { $route } = this.context();
            if ($route.query.chargingStationId) {
              options.filters.push({
                key: "chargingStationId",
                operator: "equal_to",
                value: $route.query.chargingStationId,
              });
            }

            options.extraParams.currentTimeZoneOffset = new Date().getTimezoneOffset() * -1 * 60 * 1000;

            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("chargingSessions", "findAll", options);
              return [items, count];
            },
          },
        },
      },
      methods: {
      },
    };
  },
  async created() {
    const $route = this.$route;
    if ($route.query.chargingStationId) {
      const chargingStation = await coreApiClient.call("chargingStations", "findById", {
        id: $route.query.chargingStationId,
      });
      this.chargingStation = chargingStation;
    }
  },
});
